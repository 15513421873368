@tailwind base;
@tailwind components;
@tailwind utilities;

/* VARIABLE CSS */

:root {
 --header-height: 3.275rem;
 /* Colors */
 /* Color variants */
 --gray1-color: #333333;
 --white-color: #ffffff;
 --gray2-color: #4f4f4f;
 --gray3-color: #828282;
 --gray4-color: #bdbdbd;
 --gray5-color: #e0e0e0;
 --gray6-color: #f2f2f2;
 --greenDeep-color: #01100d;
 --green-color: #27ae60;
 --kountygreen-color: #065143;
 --text-color-orange: #ea5b31;
 --container-color: #f5f5f7;
 /* Font and Typography */
 /* .5rem = 8px | 1rem = 16px */
 --body-font: "Roboto", sans-serif;
 --title-font: "Lora", serif;
 --big-font-size: 2.5rem;
 --h1-font-size: 24px;
 --h2-font-size: 20px;
 --h3-font-size: 16px;
 --normal-font-size: 15.008px;
 --small-font-size: 13.008px;
 --smaller-font-size: 12px;
 --tiny-font-size: 10px;
 /* FONT WEIGHT */
 --font-normal: 400;
 --font-medium: 500;
 --font-semi-bold: 600;
 --font-bold: 700;
 /* z Index */
 --z-normal: 1;
 --z-tooltip: 10;
 --z-fixed: 100;
}

/* @font-face {
 font-family: "Roboto_Regular";
 src: url("./assets//fonts/roboto/Roboto_Regular.ttf");
 font-weight: 700;
 font-style: normal;
}

@font-face {
 font-family: "Roboto_Light";
 src: url("./assets/fonts/roboto/Roboto_Light.ttf");
 font-weight: 600;
 font-style: normal;
}

@font-face {
 font-family: "Roboto_Medium";
 src: url("./assets/fonts/roboto/Roboto_Medium.ttf");
 font-weight: 400;
 font-style: normal;
}

@font-face {
 font-family: "Roboto_Bold";
 src: url("./assets/fonts/roboto/Roboto_Bold.ttf");
 font-weight: 300;
 font-style: normal;
}
@font-face {
 font-family: "Roboto_Black";
 src: url("./assets/fonts/roboto/Roboto_Black.ttf");
 font-weight: 300;
 font-style: normal;
} */

@media (max-width: 767px) {
 :root {
  --big-font-size: 2rem;
  --h1-font-size: 1.25rem;
  --h2-font-size: 20px;
  --h3-font-size: 16px;
  --normal-font-size: 15.008px;
  --small-font-size: 13.008px;
  --smaller-font-size: 12px;
  --tiny-font-size: 10px;
 }
}

/* Elijah started here */
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: "Merriweather", serif;
}

.contact {
 @apply flex flex-col gap-1 my-5;
}
.form-input {
 @apply flex flex-col gap-1 mb-3;
}
input[type="radio"] {
 appearance: none;
 -moz-appearance: none;
 -webkit-appearance: none;
 background-color: #fff;
 border: 2px solid #ccc;
 border-radius: 50%;
 width: 20px;
 height: 20px;
 margin: 0 5px;
 position: relative;
}
input[type="radio"]:checked {
 background-color: #27ae60;
 border-color: #042c15;
}
/* Add custom scrollbar styles */
.custom-scrollbar {
 scrollbar-width: thin; /* "auto" or "thin" for Firefox */
 scrollbar-color: #aeaeaf #f3f4f6; /* scrollbar thumb and track colors */
}

/* Style the scrollbar for Webkit browsers (Safari, Chrome) */
.custom-scrollbar::-webkit-scrollbar {
 width: 3px; /* width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
 background-color: #aeaeaf; /* color of the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
 background-color: #aeaeaf; /* color of the thumb on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
 background-color: #f3f4f6; /* color of the track */
}

.returntoshop {
 @apply px-5 py-3 border-2 hover:bg-black hover:text-white hover:scale-75 transition duration-300 ease-in text-black font-medium rounded-md;
}
.cartcheckout {
 @apply border-2 border-gray-800 px-8 py-10 xs:w-auto md:w-[400px] rounded-md;
}
.shipping {
 @apply grid grid-cols-1 gap-4 divide-y-2 divide-gray-400  font-medium text-lg;
}
.shippingItem {
 @apply flex items-center justify-between;
}
.carttablehead {
 @apply flex justify-between gap-3 lg:gap-6 items-center h-[90px] px-4 py-12 mt-8 mb-10 shadow-md;
}
.cartheader {
 @apply text-sm lg:text-lg font-medium;
}
.couponbutton {
 @apply bg-green-600 text-white hover:bg-green-400 hover:text-black rounded-md xs:text-sm md:text-lg font-medium xs:px-3 md:px-6 py-3 hover:scale-90 transition duration-300 ease-in;
}
.proceedtocheckout {
 @apply flex xs:flex-col xs:gap-8 md:flex-row justify-between mx-4 items-start my-10;
}

.icon-box {
 @apply flex justify-start items-start;
}

.productIcon {
 @apply rounded-full flex items-center justify-center mx-auto p-1 text-[25px];
}

.related {
 @apply flex gap-5 overflow-x-scroll justify-start items-center my-5 pb-4;
}
.related::-webkit-scrollbar {
 @apply hidden;
}
.cartItem {
 @apply flex justify-between gap-3 lg:gap-6 items-center h-[90px] border px-4 py-12 my-5 shadow-md;
}
.cartItemImageText {
 @apply flex xs:flex-col lg:flex-row items-center gap-4;
}

.cartItemTitle {
 @apply text-sm lg:text-lg font-bold xs:w-[80px] sm:w-[100px] md:w-[140px] lg:w-[180px] truncate;
}

.cartItemQuantity {
 @apply flex gap-4 items-center border-2 xs:px-2 sm:px-6 xs:py-1 sm:py-4 rounded-md;
}
.cartItemControls {
 @apply flex flex-col gap-3 text-xl;
}
.wishes {
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
 gap: 1rem;
}
.wishes {
 @apply place-items-center lg:place-items-start;
}
.filterheader {
 @apply text-lg font-bold mb-2;
}
.filterdiv {
 @apply mb-2 border-b-2 px-6;
}
.filterUl {
 @apply flex flex-col gap-2 text-lg overflow-y-auto custom-scrollbar;
}
.filterLi li {
 @apply flex items-center gap-[7px];
}
.account-input {
 @apply flex flex-col gap-2 my-2;
}
.input {
 @apply outline-none bg-gray-200 rounded-md p-3;
}
.account ul {
 @apply ml-6 text-lg text-gray-500 flex flex-col gap-1;
}
.account-header {
 @apply font-bold text-lg mb-3;
}

/* anyother general classes */
.submitBtn {
 @apply bg-green-500 px-6 py-3 text-white font-medium rounded-lg text-lg cursor-pointer hover:scale-75 duration-500 ease-linear;
}

.checkoutbutton{
 @apply bg-blue-600 text-white hover:bg-blue-400 hover:text-black rounded-md xs:text-sm md:text-lg font-medium xs:px-2 md:px-3 py-2 my-3 hover:scale-90 transition duration-300 ease-in;
}

.noUser{
 @apply bg-blue-400 py-1 px-2 text-white rounded-md font-medium
}
.add{
 @apply bg-gray-300 px-1 rounded-md text-sm text-black font-medium
}
.noAuction{
 @apply font-bold text-white rounded-lg bg-red-600
}
.auctionOn{
 @apply bg-green-700 font-bold text-white rounded-lg
}
.return{
 @apply rounded-xl text-white py-3 px-6 w-2/4 mx-auto hover:scale-75 duration-500 transition-all ease-in-out
}
.userTypeContainer{
 @apply flex items-center gap-8 justify-center cursor-pointer
}
.userType{
 @apply flex items-center gap-2 border-2 py-4 px-6 rounded-lg cursor-pointer
}

.count{
 @apply absolute bottom-[13px] -right-3 z-30 bg-green-400 rounded-full text-center px-2 py-1 text-xs text-white
}
.tableHeadRow{
 @apply bg-gray-200
}

thead .tableHeadRow th{
 @apply text-center
}
th, td{
 @apply border-[0.5px] border-gray-600 text-center
}

/* SIDEBAR CLASSES */

.roleLi {
 @apply flex gap-3 items-center text-xl border-b-2 pb-3 cursor-pointer text-green-500;
}
/* .roleLi:hover {
 @apply ml-3 transition-all duration-700 ease-in-out bg-green-500 text-white py-2 pl-2;
} */

.roleLi a.active {
 @apply text-black;
}

.roleLi:last-child {
 @apply border-none;
}

/* TABLE CLASSES */
.tableHead {
 @apply p-4 text-left text-[10px] md:text-[16px] font-medium text-gray-700 uppercase tracking-wider;
}
.tableRow{
 @apply even:bg-slate-50 odd:bg-slate-100 
}

/* ROLE NAVBAR CLASSES */
.navbar {
 @apply flex flex-row gap-2 justify-between items-center text-black relative px-2 py-4 bg-white shadow-md;
}
/* .navbar-nav {
 @apply flex gap-4 justify-around items-center md:w-[70%];
} */
/* .navbar-navSearch {
 @apply md:w-[60%] outline-none border-2 border-gray-300 p-2 rounded-md bg-gray-100 text-black;
} */
.navbar-navMenu {
 @apply flex gap-4 justify-evenly w-[40%] relative py-4;
}

/* MENUDROPDOWN CLASSES */
.menudropdown {
 @apply absolute w-[200px] h-auto p-3 border-2 top-[35px] right-0 bg-white shadow-md z-50;
}
.menudropdownUl {
 @apply flex flex-col gap-6 justify-center;
}
.menudropdownLi {
 @apply flex gap-2 items-center text-lg font-medium;
}

/* DROPDOWN CLASS */
.dropdownContainer {
 @apply absolute top-[45px] right-[1px] w-[250px] lg:w-[350px] h-auto p-3 flex flex-col gap-6 bg-white shadow-lg;
}
.dropdownContainer-top {
 @apply flex justify-between items-center;
}
.dropdownContainer-bottom {
 @apply flex flex-col gap-3;
}
.navbarIcon {
 @apply text-[25px];
}
.navbarIconContainer {
 @apply relative cursor-pointer;
}
.navbarIconValue {
 @apply absolute bg-green-600 text-white rounded-full py-[2px] px-2 -top-[11px] left-[10px];
}

/* DROPDOWNARTICLE */
.dropdownArticle {
 @apply flex gap-4 px-2 py-3 w-auto;
}

/* role classes */
.roleHeaders {
 @apply text-blue-500 font-bold text-xl md:text-2xl;
}
.roleBtn {
 @apply bg-blue-500 rounded-lg px-3 py-1 md:px-5 md:py-3 text-base font-semibold text-white;
}
.actionBtn {
 @apply text-white py-1 px-2 md:py-2 md:px-6 rounded-md;
}
.productIcon {
 @apply cursor-pointer;
}
.productInput {
 @apply outline-none border-2 border-blue-500 px-3 py-2 rounded-md;
}
.productForm{
 @apply flex flex-col md:flex-row gap-4 md:mx-3 w-full bg-white p-4
}
.productFormDiv {
 @apply border-2 border-gray-300 py-4 px-3 rounded-md flex flex-col gap-4;
}
.productFormDiv article label {
 @apply text-lg font-medium mb-1;
}

.roleSearch {
 @apply outline-none border-2 border-red-600 rounded-md p-3 my-2 w-full md:w-2/5;
}
.topSection {
 @apply bg-white flex justify-between items-center p-4 mb-10;
}
.formContainer {
 @apply flex flex-col justify-center px-6 py-3 gap-8 w-full md:w-[75%] mx-auto;
}
.formContainer .formDiv {
 @apply flex flex-col md:flex-row gap-2 md:gap-6 my-3 md:items-center;
}
.formDiv label {
 @apply text-lg font-medium;
}
.formDiv, .addDiv input[type="text"] {
 @apply outline-none border-2 border-blue-500 px-3 py-2 w-full rounded-md;
}
.formDiv, .addDiv input[type="file"] {
 @apply outline-none border-2 border-green-500 px-3 py-2 w-full  rounded-md;
}

.addDiv{
@apply flex flex-col justify-between gap-1
}

/* checkout item */
.checkoutItem{
 @apply relative flex flex-col justify-between border-b-2 border-gray-300 pb-2 w-auto
}
.checkoutItem .checkoutItemDetails{
 @apply flex justify-between gap-3 items-center
}
.checkoutItem .checkoutItemDetails .checkoutItemName{
 @apply font-medium truncate w-[70%] xl:w-[90%]
}
.checkoutItem .checkoutItemDetails .checkoutQuantity{
 @apply absolute bg-slate-600 text-white px-2 py-1 rounded-full top-[5px] right-0
}

/* checkout page */
.total{
 @apply flex justify-between items-center py-4 px-2 bg-gray-200 my-2
}
.billing{
 @apply flex flex-col gap-2
}
.billing article{
@apply flex flex-col gap-1 w-full
}
.billing article span{
@apply text-red-600 text-lg font-extrabold
}
.billing article label{
@apply  text-lg font-medium text-gray-400
}
.billing article input, select{
 @apply outline-none border-2 border-blue-500 px-3 py-2 w-full rounded-md
}

.payment article{
 @apply flex gap-4 items-center bg-slate-300 py-6 px-3 my-4 rounded-sm
}

/* vendors page */
.vendorsPageTopSection{
 @apply my-4 flex flex-col justify-center items-center w-full
}
.title{
 @apply text-[35px] lg:text-[60px] font-bold text-center text-gray-800
}
.vendorsPageTopSection div{
 @apply my-4 w-[95%] lg:w-[70%] mx-auto
}
.vendorsPageTopSection div article{
 @apply py-4 relative w-full lg:w-[70%] mx-auto
}
.vendorsPageTopSection div article input{
 @apply w-full shadow-lg border-[2px] outline-none py-4 indent-7 rounded-full
}
.vendorsPageTopSection div article .icon{
 @apply absolute right-[10px] top-[30%] text-[35px]
}

.allVendors{
 @apply flex flex-row flex-wrap gap-[20px] items-center justify-evenly xs:w-[95%] lg:w-[90%] mx-auto py-4
}
/* .allVendors{
 @apply grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[20px] place-items-center place-self-center xs:w-[95%] lg:w-[90%] mx-auto py-4
} */
.allVendorProducts{
 @apply grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center xs:w-[95%] lg:w-[90%] mx-auto py-4
}
.paginateContainer{
 @apply flex justify-center items-center space-x-2
}
.nextPrev{
 @apply cursor-pointer py-1 text-lg px-2 rounded-full bg-gray-200 hover:bg-gray-300
}
.pageNumber{
 @apply cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white
}

/* single Vendor */
.singleVendor .topSection{
 @apply flex justify-center my-4 
}
.singleVendor .bottomSection{
 @apply flex flex-col-reverse lg:flex-row gap-[15px] justify-between px-4
}
.singleVendor .bottomSection .detailsArea{
 @apply flex flex-col gap-4 lg:w-[20%]
}
.singleVendor .bottomSection .vendordate{
 @apply text-gray-400 font-medium text-base
}
.singleVendor .bottomSection .vendorName{
 @apply font-bold text-2xl
}
.singleVendor .bottomSection .vendorInfoText{
 @apply font-normal text-base
}
.singleVendor .bottomSection .socials h3{
 @apply font-medium text-lg
}
.singleVendor .bottomSection .socials .social-icons{
 @apply flex gap-4 my-3
}
.singleVendor .bottomSection .socials .social-icons a{
 @apply transform hover:-translate-y-[10px] transition-all duration-200 ease-out
}

.singleVendor .bottomSection .socials .location div{
 @apply flex flex-col gap-2 my-3 items-start
}
.singleVendor .bottomSection .socials .location svg{
 @apply text-2xl
}
.singleVendor .bottomSection .detailsArea .aboutDetails{
 @apply flex flex-col gap-4 bg-blue-200 py-8 px-4 rounded-[30px]
}
.singleVendor .bottomSection .detailsArea .aboutCategories{
 @apply bg-white shadow-lg border-[0.5px] border-gray-200 py-8 px-4 rounded-[30px]
}
.singleVendor .bottomSection .detailsArea .aboutCategories h3{
 @apply font-medium text-lg
}
.singleVendor .bottomSection .detailsArea .aboutCategories ul{
 @apply flex flex-col gap-3 my-3
}
.singleVendor .bottomSection .detailsArea .aboutCategories li{
 @apply flex  justify-between items-center border-[0.5px] rounded-md py-2 px-1 my-[2px] hover:border-green-400 hover:border-2 hover:shadow-md cursor-pointer text-gray-600
}
.aboutCategories p{
 @apply bg-green-200 py-1 px-2 rounded-full text-black
}
.singleVendor .bottomSection .productsArea{
 @apply lg:w-[80%]
}
.singleVendor .bottomSection .detailsArea .aboutFilter{
 @apply bg-white shadow-lg border-[0.5px] border-gray-200 py-8 px-4 rounded-[30px]
}
.singleVendor .bottomSection .detailsArea .aboutFilter h3{
 @apply font-medium text-lg
}

/* role profile */
.profile{
 @apply flex xs:flex-col md:flex-row w-full lg:max-w-[80%] my-[30px] mx-auto bg-white p-[15px] xs:gap-[30px] lg:gap-[15%] shadow-lg border-[1px] border-gray-100
}
.profile_picture figure img{
 @apply w-[150px] h-[150px] object-cover rounded-md border-2 border-red-500 
}
.profile_info{
 @apply flex flex-col gap-4 w-full lg:w-[75%]
}
.profile_info form{
 @apply flex flex-col gap-4
}
.profile_info form article{
 @apply flex flex-col gap-2
}
.profile_info form article label{
 @apply text-lg font-semibold
}
.profile_info form article input{
 @apply text-lg outline-none border-b-2 border-b-gray-900
}
.disabled{
 @apply cursor-not-allowed bg-slate-200 text-black p-4 rounded-lg font-semibold
}
.cancel{
 @apply bg-red-600 text-white p-4 rounded-lg font-semibold
}
/* all admin vendors */
.vendorBtn{
 @apply border-2 border-gray-950 px-4 py-2 rounded-2xl
}
.currentVendor{
 @apply border-none bg-green-600 px-4 py-2 rounded-2xl text-white
}

/* MODAL SINGLE PRODUCT */
.modalProduct{
 @apply absolute w-[90%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white py-4 px-6 rounded-lg
}
.modalClose{
 @apply ml-auto flex justify-end
}
.modalButton{
 @apply bg-white rounded-full text-red-600 py-1 px-2 text-lg cursor-pointer
}

/* Auctions page and components */
.auctionPage{
 @apply flex flex-col lg:flex-row gap-8 my-8 relative
}
.auctionPage #auctionPage_mobileFilter{
 @apply flex  lg:hidden ml-2
}

#mobile_filter{
 @apply absolute w-full h-full px-4 py-2 font-medium
}
#mobile_filter{
 background: rgba(255, 255, 255, 0.8);
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0.3);
}

#mobile_filter .top{
 @apply flex justify-between items-center text-xl
}
#mobile_filter .bottom{
 @apply flex flex-col gap-8 justify-between text-lg mt-8
}
.buttons{
 @apply flex flex-col gap-4 justify-between text-lg mt-8
}
.buttons button{
 @apply bg-green-600 text-white py-1 rounded-sm
}
#mobile_filter .bottom article{
 @apply flex flex-col gap-2 justify-between border-b-2 border-gray-600 pb-6
}
#mobile_filter .bottom article label{
 @apply flex flex-row justify-between
}

.auctionPage label{
 @apply font-medium text-base
}
.auctionPage #auctionPage_mobileFilter button{
 @apply border-2 border-gray-300 py-1 px-3 bg-transparent text-black flex gap-1 items-center justify-center text-lg font-semibold rounded-md
}
.auctionPage #auctionPage_desktopFilter{
 @apply hidden lg:flex flex-col gap-4 w-[25%] rounded-xl shadow-2xl bg-white py-6 px-2 ml-2
}
.auctionPage #auctionPage_desktopFilter article{
 @apply flex flex-col gap-2 justify-between
}
.auctionPage #auctionPage_auctions{
 @apply w-full lg:w-[80%] bg-white py-3 px-2 flex flex-col justify-center items-center
}

.auctionCard{
 @apply flex flex-col sm:flex-row gap-3 py-2 w-full h-auto border-b-2 border-gray-200 my-1 px-3 cursor-pointer
}
.auctionCard figure{
 @apply w-full sm:w-[20%] relative  flex justify-center items-center
}
.auctionCard figure figcaption {
 @apply absolute top-3/4 left-2 text-[14px] xl:text-[16px]
}
.auctionCard figure img{
 @apply w-[200px] h-[130px] object-contain
}
.auctionCard #auctionCard_details{
 @apply flex flex-col gap-3 justify-evenly w-full sm:w-[80%]
}
.auctionCard #auctionCard_details article{
 @apply flex flex-col sm:flex-row gap-5 items-start
}
.auctionCard #auctionCard_details article h3,h1{
 @apply font-bold
}
.auctionCard #auctionCard_details article div{
 @apply flex flex-col gap-[2px] w-full sm:w-[55%]
}
.auctionCard #auctionCard_details article div h2{
 @apply w-full sm:w-[80%] truncate text-blue-800 font-bold underline underline-offset-4 cursor-pointer
}
.auctionCard #auctionCard_details article div p{
 @apply w-full sm:w-[80%] text-[#8f8d8d]
}

/* coupon page */
.couponArticle{
 @apply flex flex-col gap-2 justify-start
}
.couponInput{
 @apply outline-none border-2 border-green-500 px-3 py-2 w-full  rounded-md
}

.loadingDiv{
 position: absolute;
 z-index: 1000;
 background: rgba(0, 0, 0, 0.52);
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(10.1px);
 -webkit-backdrop-filter: blur(10.1px);
}

.parallelogram {
  padding: 20px; 
}
@media (max-width: 568px) {
  .parallelogram{
    clip-path: polygon(0 0, 60% 0, 50% 100%, 0% 100%);
  }
}

@media (min-width: 768px) {
  .parallelogram{
    clip-path: polygon(0 0, 30% 0, 20% 100%, 0% 100%);
  }
}